<template>
    <!-- card -->
    <div class="card space-y-2">
        <!--Image and actions-->
        <div id="cardImage"
            class="relative w-full border bg-neutral-900 border-neutral-800 rounded-lg overflow-hidden"
            :class="type == 'banner' ? 'aspect-[20/7]' : type == 'movie' ? 'aspect-[7/10]' : type == 'logo' ? 'aspect-video' : type == 'product' ? 'aspect-square' : ''">
            <div class="conten w-full h-full" v-if="!skeleton">
                <img :src="type == 'banner' || type == 'logo' ? data.url : type == 'movie' ? data.poster.path : type == 'product' ? data.image.path : ''"
                    class="w-full h-full object-center" :class="type == 'logo' ? 'object-contain' : 'object-cover'"
                    v-if="data.url || (data.poster && data.poster.path) || data.image">
                <!-- loading image -->
                <div class="remove absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer hover:bg-black hover:bg-opacity-80 hover:scale-125 transition-all duration-100"
                    v-if="isLoading">
                    <div class="remove-icon p-3 rounded-full border border-white">
                        <svg width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" r="40" stroke="#FFF" fill="none" stroke-width="10"
                                stroke-dasharray="62.83 62.83">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                    dur="1s" keyTimes="0;1" values="0 50 50;360 50 50" />
                                <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1s"
                                    keyTimes="0;1" values="1 200;80 200;1 200" />
                            </circle>
                        </svg>
                    </div>
                </div>
                <!-- delete image -->
                <div class="remove absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer hover:bg-black hover:bg-opacity-80 hover:scale-125 transition-all duration-100"
                    v-else-if="data.url || (data.poster && data.poster.path) || data.image" @click="removeFile()">
                    <div class="remove-icon p-3 rounded-full border border-white">
                        <Icon iconImg="/assets/icons/trash-outline.svg" class="w-5 bg-white"></Icon>
                    </div>
                </div>
                <!-- uploader-->
                <label v-else>
                    <div
                        class="uploader absolute top-0 left-0 cursor-pointer w-full h-full flex items-center justify-center hover:scale-125 transition-all duration-100">
                        <div class="upload-icon p-3 rounded-full border border-white">
                            <Icon iconImg="/assets/icons/plus-solid.svg" class="w-5 bg-white" />
                        </div>
                    </div>
                    <input type="file" name="file" style="display: none" accept="image/jpg,image/jpeg,image/png"
                        @change="uploadFile($event)" />
                </label>
            </div>
            <!--Skeleton image-->
            <div class="w-full h-full bg-neutral-700 animate-pulse" v-else> </div>
        </div>

        <!--Name-->
        <p v-if="type != 'banner' && data.nombre && !skeleton"
            class="text-white truncate font-semibold text-sm mt-2 opacity-75 lg:text-lg uppercase">{{ data.nombre }}</p>

        <!--Skeleton name-->
        <div class="textSkeleton w-full h-5 lg:h-7 bg-neutral-700 animate-pulse rounded-lg" v-if="type != 'banner' && type != 'logo' && skeleton"> </div>
    </div>
</template>

<script lang="ts">
//custom components
import Icon from './Icon.vue';

//Utilities
import utilities from '@/utilities';

//this component
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CardComponent',
    mixins: [utilities],
    components: {
        Icon
    },
    emits: ['uploaded', 'deleted'],
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        type: {
            type: String,
            default: 'banner'
        },
        skeleton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        async uploadFile(event: Event) {
            // get file input
            const input = event.target as HTMLInputElement

            if (input.files) {
                //set loaging status
                this.isLoading = true

                //get file
                const file = input.files[0]

                //get cinema id
                const cinemaId = this.type == 'banner' ? this.$store.state.cinemas[0].cinemaId : this.type == 'logo' ? this.data.cinemaId : this.$store.state.selectedCinema

                //get company id
                const companyId = this.$store.state.user.companyId

                let fileId = this.type
                let contentType = this.type

                if (this.type == 'movie') {
                    fileId = this.data.codigoPelicula
                    contentType = 'poster'
                }
                else if (this.type == 'product') {
                    fileId = this.data.codigo
                }

                const response = await this.uploadImage({
                    file: file as File,
                    cinemaId: cinemaId,
                    companyId: companyId,
                    fileId: fileId,
                    contentType: contentType
                })

                const jsonResponse = JSON.parse(response.data as string)

                if ((jsonResponse.status == 'ok')) {
                    this.$emit('uploaded', jsonResponse)
                }
                else {
                    //const toast = (this.$refs as any).toast
                    //toast.show(jsonResponse.message)
                    console.log("Error")
                }

                this.isLoading = false
            }
        },
        async removeFile() {
            this.isLoading = true

            if (this.type == 'banner') {
                await this.deleteImage(this.data.id)
                this.$emit('deleted', this.data.id)
            }
            else if(this.type == 'movie') {
                await this.deleteImage(this.data.poster.id)
                this.$emit('deleted', this.data.codigoPelicula)
            }
            else if (this.type == 'logo') {
                await this.deleteImage(this.data.id)
                this.$emit('deleted', this.data.cinemaId)
            }
            else if (this.type == 'product') {
                await this.deleteImage(this.data.image.id)
                this.$emit('deleted', this.data.codigo)
            }

            this.isLoading = false
        }
    }
})
</script>